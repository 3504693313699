<template>
  <section class="cont hotel-add">
    <!-- 面包屑 -->
    <el-row class="crumbs-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>AIOT</el-breadcrumb-item>
        <el-breadcrumb-item>酒店管理</el-breadcrumb-item>
        <el-breadcrumb-item to="/hotel_manage">酒店列表</el-breadcrumb-item>
        <el-breadcrumb-item>{{ action === 'add' ? '新建' : '编辑' }}酒店</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <!-- 返回页头 -->
    <el-page-header @back="goBack" :content="`${ action === 'add' ? '新建' : '编辑' }酒店`"></el-page-header>
    <el-row class="cont-form-box">
      <!-- 步骤条 -->
      <el-steps :active="active" simple>
        <el-step title="基本信息"></el-step>
        <el-step title="详细信息"></el-step>
      </el-steps>
      <!-- 酒店新增 -->
      <div class="det-box">
        <div class="box-info">
          <!-- 详细信息 -->
          <el-row class="m-bottom-15 box-border">
            <el-row class="m-bottom-15 box-bg">
              <el-col :span="24" class="p-left-15 l-height-40"><span v-text="$t('msg.hotel_info')">酒店详细信息</span></el-col>
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40"><span v-text="$t('msg.hotel_star_a')">酒店星级：</span></el-col>
              <el-col :span="10">
                <el-rate class="rate-number" :max="7" v-model="star"></el-rate>
              </el-col>
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40"><span v-text="$t('msg.hotel_area_a')">酒店面积：</span></el-col>
              <el-col :span="10">
                <el-input style="width: 100%;" v-model="area" :placeholder="$t('msg.hotel_area')" oninput="value=value.replace(/[^\d]/g,'')" clearable></el-input>
              </el-col>
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40"><span v-text="$t('msg.hotel_booking_a')">最多预订天数：</span></el-col>
              <el-col :span="10">
                <el-input style="width: 100%;" v-model="reserveDayMax" :placeholder="$t('msg.hotel_booking')" oninput="value=value.replace(/[^\d]/g,'')" maxLength='3' clearable></el-input>
              </el-col>
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40"><span v-text="$t('msg.hotel_in_time_a')">入住时间：</span></el-col>
              <el-col :span="10">
                <el-time-picker is-range v-model="inTime" :range-separator="$t('msg.to')" :start-placeholder="$t('msg.time_starting')" :end-placeholder="$t('msg.time_end')">
                </el-time-picker>
              </el-col>
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40"><span v-text="$t('msg.hotel_breakfast_time_a')">早餐时间：</span></el-col>
              <el-col :span="10">
                <el-time-picker is-range v-model="breakfastTime" :range-separator="$t('msg.to')" :start-placeholder="$t('msg.time_starting')" :end-placeholder="$t('msg.time_end')" style="width: 100%;">
                </el-time-picker>
              </el-col>
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40"><span v-text="$t('msg.hotel_out_time_a')">退房时间：</span></el-col>
              <el-col :span="10">
                <el-time-select :placeholder="$t('msg.hotel_out_time')" v-model="checkoutTime" :picker-options="{
                                 start: '00:00',
                                 step: '00:30',
                                 end: '24:00'
                               }" style="width: 100%;">
                </el-time-select>
              </el-col>
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40">
                <span v-text="$t('msg.hotel_reception_phone_a')">前台电话：</span>
              </el-col>
              <el-col :span="4">
                <el-input style="width: 100%;" v-model="frontDeskTel.areaCode" :placeholder="$t('msg.area_code')" maxLength='4' clearable></el-input>
              </el-col>
              <el-col :span="6" class="t-right">
                <el-input style="width: 90%;" v-model="frontDeskTel.faxNumber" :placeholder="$t('msg.hotel_phone')" oninput="value=value.replace(/[^\d]/g,'')" maxLength='11' clearable></el-input>
              </el-col>
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40"><span>小程序订房酒店列表查询方式：</span></el-col>
              <el-col :span="10">
                <el-radio-group v-model="appletsHotelListType">
                  <el-radio label="ALL">全部</el-radio>
                  <el-radio label="PORTION">集团下所有酒店</el-radio>
                  <el-radio label="CURRENT">当前酒店</el-radio>
                </el-radio-group>
              </el-col>
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40"><span v-text="$t('msg.hotel_website_a')">酒店官网：</span></el-col>
              <el-col :span="10">
                <el-input style="width: 100%;" v-model="website" :placeholder="$t('msg.hotel_website')" clearable></el-input>
              </el-col>
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40"><span>智能语音欢迎语：</span></el-col>
              <el-col :span="10">
                <el-input class="type-textarea" type="textarea" v-model="greeting" placeholder="智能语音欢迎语"></el-input>
                <p style="font-size: 12px;color: #999999">{userName}：代表入住用户姓名</p>
              </el-col>
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40"><span>预定需知：</span></el-col>
              <el-col :span="10">
                <el-input class="type-textarea" type="textarea" v-model="reserveNotes" placeholder="预定需知"></el-input>
              </el-col>
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40"><span v-text="$t('msg.hotel_notice_a')">入住须知：</span></el-col>
              <el-col :span="10">
                <el-input class="type-textarea" type="textarea" v-model="checkinNotes" :placeholder="$t('msg.hotel_notice')"></el-input>
              </el-col>
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40"><span v-text="$t('msg.hotel_introduction_a')">酒店介绍：</span></el-col>
              <el-col :span="10">
                <el-input class="type-textarea" type="textarea" v-model="description" :placeholder="$t('msg.hotel_introduction')"></el-input>
              </el-col>
            </el-row>
          </el-row>
          <!-- 赛菲姆云配置 -->
          <el-row class="m-bottom-15 box-border">
            <el-row class="m-bottom-15 box-bg">
              <el-col :span="24" class="p-left-15 l-height-40"><span>赛菲姆云配置</span></el-col>
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40"><span>社区编号：</span></el-col>
              <el-col :span="10">
                <el-input style="width: 100%;" v-model.trim="hotelCfg.SFIRM.communityNo" placeholder="" clearable></el-input>
              </el-col>
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40"><span>房屋编号：</span></el-col>
              <el-col :span="10">
                <el-input style="width: 100%;" v-model.trim="hotelCfg.SFIRM.houseNo" placeholder="" clearable></el-input>
              </el-col>
            </el-row>
          </el-row>
          <!-- 支付配置 -->
          <el-row class="m-bottom-15 box-border">
            <el-row class="m-bottom-15 box-bg">
              <el-col :span="24" class="p-left-15 l-height-40"><span>支付配置</span></el-col>
            </el-row>
            <el-row class="p-left-60">
              微信
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40"><span>商户号：</span></el-col>
              <el-col :span="10">
                <el-input style="width: 100%;" :disabled="isYspay" v-model.trim="hotelCfg.WECHAT_PROVIDER.subMchId" placeholder="" clearable></el-input>
                <el-row class="m-bottom-10 m-top-10">
                    <el-checkbox v-model="hotelCfg.WECHAT_PROVIDER.directPay" :disabled="isYspay">支付直连商户</el-checkbox>
                </el-row>
                <div v-if="!hotelCfg.WECHAT_PROVIDER.directPay">
                  <el-radio-group :disabled="isYspay" class="m-right-30" v-model="hotelCfg.WECHAT_PROVIDER.profitSharing" @change="changeProfiting">
                  <el-radio :label="true">同意分账</el-radio>
                  <el-radio :label="false">不同意分账</el-radio>
                </el-radio-group>
                </div>
                <el-col style="display: flex;margin-top: 10px;line-height: 28px">
                  <el-checkbox :disabled="isYspay" v-model="hotelCfg.WECHAT_PROVIDER.sharingPlatform">平台抽佣</el-checkbox>
                  <el-input :disabled="isYspay" @change="verifyRate(hotelCfg.WECHAT_PROVIDER.rate)" v-if="hotelCfg.WECHAT_PROVIDER.sharingPlatform" size="mini" class="m-left-30" v-model="hotelCfg.WECHAT_PROVIDER.rate">
                    <template slot="append">%</template>
                  </el-input>
                </el-col>

              </el-col>
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40"><span>全员营销模式：</span></el-col>
              <el-col :span="12">
                <el-radio-group :disabled="isYspay" class="m-top-15" v-model="hotelCfg.WECHAT_PROVIDER.relationFixed">
                  <el-radio :label="true">终身制（默认项，会员关系固定）</el-radio>
                  <el-radio :label="false">非终身制（营销会员关系动态变化）</el-radio>
                </el-radio-group>

              </el-col>
            </el-row>
            <el-row class="m-bottom-30"></el-row>
            <el-row class="p-left-60">
              支付宝
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40"><span>商户号：</span></el-col>
              <el-col :span="10">
                <el-input :disabled="isYspay" style="width: 100%;" v-model.trim="hotelCfg.ALIPAY.appAuthToken" placeholder="" clearable></el-input>
              </el-col>
            </el-row>
            <el-row class="m-bottom-30"></el-row>
            <el-row class="p-left-60">
              银盛支付
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="10" class=" t-right l-height-40">
                <el-checkbox v-model="isCheckYsepay">唯一收款方式</el-checkbox>
              </el-col>
              <el-col :span="10">
                <!-- <el-checkbox>唯一收款方式</el-checkbox> -->
              </el-col>
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40"> <span>抽佣比例：</span></el-col>
              <el-col :span="10">
                <el-input v-model="ysepayCommissionRate">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
            </el-row>
          </el-row>
          <!-- 邮件配置 -->
          <el-row class="m-bottom-15 box-border">
            <el-row class="m-bottom-15 box-bg">
              <el-col :span="24" class="p-left-15 l-height-40"><span>邮件配置</span></el-col>
            </el-row>
            <el-row class="p-left-60">
              163邮箱：
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40"><span>邮箱号：</span></el-col>
              <el-col :span="10">
                <el-input style="width: 100%;" v-model.trim="hotelCfg.MAIL_163.username" placeholder="" clearable></el-input>
              </el-col>
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40" style="margin-top: 20px"><span>密码：</span></el-col>
              <el-col :span="10" style="margin-top: 20px">
                <el-input style="width: 100%;" v-model.trim="hotelCfg.MAIL_163.password" show-password placeholder="" clearable></el-input>
              </el-col>
            </el-row>
            <el-row class="m-bottom-30"></el-row>
            <el-row class="p-left-60">
              QQ邮箱
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40"><span>邮箱号：</span></el-col>
              <el-col :span="10">
                <el-input style="width: 100%;" v-model.trim="hotelCfg.MAIL_QQ.username" placeholder="" clearable></el-input>
              </el-col>
            </el-row>
            <el-row class="m-bottom-15">
              <el-col :span="8" class="t-right l-height-40" style="margin-top: 20px"><span>密码：</span></el-col>
              <el-col :span="10" style="margin-top: 20px">
                <el-input style="width: 100%;" v-model.trim="hotelCfg.MAIL_QQ.password" show-password placeholder="" clearable></el-input>
              </el-col>
            </el-row>
          </el-row>
          <!-- 酒店房型 -->
          <el-row class="m-bottom-15 box-border">
            <el-row class="box-bg">
              <el-col :span="24" class="p-left-15 l-height-40">
                <el-checkbox @change="checkAllRoomType" :indeterminate="isRoomTypeIndet" v-model="isRoomTypeAll">
                  <span v-text="$t('msg.hotel_room_type')">酒店房型</span>
                </el-checkbox>
              </el-col>
            </el-row>
            <el-row class="box_list">
              <el-checkbox-group v-model="checkRoomTypes" @change="checkedRoomType">
                <el-col v-for="(item, index) in roomTypes" :key="index" :span="4">
                  <el-checkbox v-if="!item.tempShow" :label="item.id">{{item.roomTypeName}}</el-checkbox>
                  <span class="box-inline" v-if="item.addTemp" @click="dialogRoomType = true">
                    <i class="el-icon-plus" style="font-weight: 800;"></i>
                    <span class="p-left-10" v-text="$t('msg.add')">添加</span>
                  </span>
                </el-col>
              </el-checkbox-group>
            </el-row>
          </el-row>
          <!-- 酒店设施 -->
          <el-row class="m-bottom-15 box-border">
            <el-row class="box-bg">
              <el-col :span="24" class="p-left-15 l-height-40">
                <el-checkbox @change="checkAllFacility" :indeterminate="isFacilityIndet" v-model="isFacilityAll">
                  <span v-text="$t('msg.hotel_facilities')">酒店设施</span>
                </el-checkbox>
              </el-col>
            </el-row>
            <el-row class="box_list">
              <el-checkbox-group v-model="checkFacilities" @change="checkedFacility">
                <el-col v-for="(item, index) in facilities" :key="index" :span="4">
                  <el-checkbox v-if="!item.tempShow" :label="item.id">{{item.name}}</el-checkbox>
                  <span class="box-inline" v-if="item.addTemp" @click="dialogFacility = true">
                    <i class="el-icon-plus" style="font-weight: 800;"></i>
                    <span class="p-left-10" v-text="$t('msg.add')">添加</span>
                  </span>
                </el-col>
              </el-checkbox-group>
            </el-row>
          </el-row>
          <!-- 小程序 -->
          <el-row class="m-bottom-15 box-border">
            <el-row class="box-bg">
              <el-col :span="24" class="p-left-15 l-height-40">
                <el-checkbox @change="checkAllAppletMenu" :indeterminate="isAppletIndet" v-model="isAppletAll">
                  <span v-text="$t('msg.hotel_applet')">小程序选项卡权限</span>
                </el-checkbox>
              </el-col>
            </el-row>
            <el-row class="box_list">
              <el-checkbox-group v-model="checkMenus" @change="checkedAppletMenu">
                <el-col v-for="(item, index) in appletMenus" :key="index" :span="4">
                  <el-checkbox :label="item.id">{{item.name}}</el-checkbox>
                </el-col>
              </el-checkbox-group>
            </el-row>
          </el-row>
          <!-- 通知 -->
          <el-row class="m-bottom-15 box-border">
            <el-row class="box-bg">
              <el-col :span="24" class="p-left-15 l-height-40">
                <el-checkbox :disabled="true">设置通知模块</el-checkbox>
              </el-col>
            </el-row>
            <el-row class="box_list notice">
              <el-button type="primary" @click="setNoticeTemplate('WECHAT_APPLET', 'CLIENT')">添加小程序通知模板</el-button>
              <el-button type="primary" @click="setNoticeTemplate('WECHAT_OFFICIAL', 'COMMERCIAL_TENANT')">添加公众号通知模板</el-button>
              <el-button type="primary" @click="setNoticeTemplate('PLATFORM', 'COMMERCIAL_TENANT')">添加后台通知模板</el-button>
            </el-row>
          </el-row>
          <el-row class="m-bottom-15 next-button">
            <el-button class="bg-gradient" style="margin-top: 12px;" @click="saveHotelDetail" v-text="$t('msg.save')">保存</el-button>
          </el-row>
        </div>
      </div>
      <!-- 新建房型 -->
      <el-form label-width="105px">
        <el-dialog :title="$t('msg.add_room_type')" :visible.sync="dialogRoomType" width="510px" :before-close="handleClose">
          <el-row class="m-bottom-15">
            <el-col :span="8" class="t-right l-height-26"><b class="needful">*</b><span v-text="$t('msg.room_icon_a')">图标：</span></el-col>
            <el-col :span="16">
              <template>
                <el-radio v-model="radioIcon" label="1"><span v-text="$t('msg.font_icon')">字体图标</span></el-radio>
                <el-radio v-model="radioIcon" label="2"><span v-text="$t('msg.upload_icon')">上传图标</span></el-radio>
              </template>
            </el-col>
            <el-col :span="16" class="suffix-icon">
              <el-input class="width-260" v-if="radioIcon === '1'" :placeholder="$t('msg.font_icon')" clearable></el-input>
              <el-input class="width-260" v-else :placeholder="$t('msg.upload_icon')">
                <i slot="suffix" class="el-input__icon el-icon-upload"></i>
              </el-input>
            </el-col>
          </el-row>
          <el-row class="m-bottom-15">
            <el-col :span="8" class="t-right l-height-26"><b class="needful">*</b><span v-text="$t('msg.roomtype_name_a')">房型名称：</span></el-col>
            <el-col :span="16">
              <el-input class="width-260" v-model="roomTypeName" :placeholder="$t('msg.roomtype_name')" clearable></el-input>
            </el-col>
          </el-row>
          <el-row class="m-bottom-15">
            <el-col :span="8" class="t-right l-height-26"><b class="needful">*</b><span v-text="$t('msg.roomtype_code_a')">房型编码：</span></el-col>
            <el-col :span="16">
              <el-input class="width-260" v-model="roomTypeCode" :placeholder="$t('msg.roomtype_code')" clearable></el-input>
            </el-col>
          </el-row>
          <el-row class="m-bottom-15">
            <el-col :span="8" class="t-right l-height-26"><span v-text="$t('msg.remarks_a')">备注信息：</span></el-col>
            <el-col :span="16">
              <el-input class="width-260 type-textarea" type="textarea" v-model="roomTypeRemark" :placeholder="$t('msg.inp_remark_info')"></el-input>
            </el-col>
          </el-row>
          <span slot="footer" class="dialog-footer">
            <el-button @click="resetRoomType()" v-text="$t('msg.reset')">重置</el-button>
            <el-button type="primary" @click="saveRoomType" v-text="$t('msg.save')">保存</el-button>
          </span>
        </el-dialog>
      </el-form>
      <!-- 新建设施 -->
      <el-form label-width="105px">
        <el-dialog :title="$t('msg.add_facilities')" :visible.sync="dialogFacility" width="510px" :before-close="handleClose">
          <el-row class="m-bottom-15">
            <el-col :span="8" class="t-right l-height-26"><b class="needful">*</b><span v-text="$t('msg.room_icon_a')">图标：</span></el-col>
            <el-col :span="16">
              <template>
                <el-radio v-model="fadioIcon" label="1"><span v-text="$t('msg.font_icon')">字体图标</span></el-radio>
                <el-radio v-model="fadioIcon" label="2"><span v-text="$t('msg.upload_icon')">上传图标</span></el-radio>
              </template>
            </el-col>
            <el-col :span="16" class="suffix-icon">
              <el-input class="width-260" v-if="fadioIcon === '1'" :placeholder="$t('msg.font_icon')" clearable></el-input>
              <el-input class="width-260" v-else :placeholder="$t('msg.upload_icon')">
                <i slot="suffix" class="el-input__icon el-icon-upload"></i>
              </el-input>
            </el-col>
          </el-row>
          <el-row class="m-bottom-15">
            <el-col :span="8" class="t-right l-height-26"><b class="needful">*</b><span v-text="$t('msg.facilities_name_a')">设施名称：</span></el-col>
            <el-col :span="16">
              <el-input class="width-260" v-model="name" :placeholder="$t('msg.facilities_name')" clearable></el-input>
            </el-col>
          </el-row>
          <el-row class="m-bottom-15">
            <el-col :span="8" class="t-right l-height-26"><b class="needful">*</b><span v-text="$t('msg.facilities_code_a')">设施编码：</span></el-col>
            <el-col :span="16">
              <el-input class="width-260" v-model="code" :placeholder="$t('msg.facilities_code')" clearable></el-input>
            </el-col>
          </el-row>
          <el-row class="m-bottom-15">
            <el-col :span="8" class="t-right l-height-26"><span v-text="$t('msg.remarks_a')">备注信息：</span></el-col>
            <el-col :span="16">
              <el-input class="width-260 type-textarea" type="textarea" v-model="remark" :placeholder="$t('msg.inp_remark_info')"></el-input>
            </el-col>
          </el-row>
          <span slot="footer" class="dialog-footer">
            <el-button @click="resetFacility" v-text="$t('msg.reset')">重置</el-button>
            <el-button type="primary" @click="saveFacility" v-text="$t('msg.save')">保存</el-button>
          </span>
        </el-dialog>
      </el-form>
    </el-row>
  </section>
</template>
<script>
import { urlObj } from '@/api/interface'
import { pms } from '@/api/interface/pms'
import { system } from '@/api/interface/system'
import { CustomObject } from '@/common/js/common'
import { setYspay, getMerchantINfo } from '@/api/system/ysManagement'
export default {
  data() {
    return {
      active: 1, // 进度条状态
      fadioIcon: '1', // 图标按钮(新建设施)
      radioIcon: '1', // 图标按钮(新建房型)
      dialogFacility: false, // 新建设施会话框
      dialogRoomType: false, // 新建房型会话框
      action: '',
      /* 酒店房型 */
      roomTypes: [], // 房型列表
      checkRoomTypes: [], // 选中房型列表
      isRoomTypeIndet: false, // 酒店房型是否半选
      isRoomTypeAll: false, // 酒店房型是否全选
      /* 酒店设施 */
      facilities: [], // 设施列表
      checkFacilities: [], // 选中设施列表
      isFacilityIndet: false, // 酒店设施是否半选
      isFacilityAll: false, // 酒店设施是否全选
      /* 小程序菜单 */
      appletMenus: [], // 小程序菜单列表
      checkMenus: [], // 选中小程序菜单列表
      storageMenus: [], // 存储返回的小程序菜单列表
      isAppletIndet: false, // 小程序菜单是否半选
      isAppletAll: false, // 小程序菜单是否全选
      delMenuIds: [], // 操作后删除的菜单
      menuIds: [], // 操作后新增的菜单
      /* 酒店详情表单字段 */
      hotelId: '', // 酒店id
      description: '', // 酒店介绍
      checkinNotes: '', // 入住须知
      greeting: '', // 智能语音欢迎语
      reserveNotes: '', // 预定需知
      website: '', // 酒店官网
      appletsHotelListType: 'ALL', // 小程序订房酒店列表查询方式
      checkoutTime: '', // 退房时间
      star: null, // 酒店星级
      reserveDayMax: '', // 最多预订天数
      area: '', // 酒店面积
      breakfastTime: [new Date(), new Date()], // 早餐时间
      inTime: [new Date(), new Date()], // 入住时间
      frontDeskTel: {
        // 前台电话
        areaCode: '', // 区号
        faxNumber: '', // 传真号
      },
      hotelCfg: {
        WECHAT_PROVIDER: {
          subMchId: '', // 微信商户号
          profitSharing: null,
          sharingPlatform: true,
          rate: '2',
          relationFixed: true,
          directPay: false
        },
        MAIL_QQ: {
          username: '', // QQ邮箱账号
          password: '', // QQ邮箱密码
        },
        MAIL_163: {
          username: '', // QQ邮箱账号
          password: '', // QQ邮箱密码
        },
        ALIPAY: {
          appAuthToken: '', // 支付宝商户号
        },
        SFIRM: {
          communityNo: '', // 赛菲姆云社区编号
          houseNo: '', // 赛菲姆云房屋编号
        },
      },
      isYspay: false,
      isCheckYsepay: false, // 是否银盛支付
      ysepayCommissionRate: 0, //银盛支付抽佣比例
      ysepayStatus: '01', // 银盛支付状态  默认未进件
      /* 新建房型 */
      roomTypeName: '', // 房型名称
      roomTypeCode: '', // 房型编码
      roomTypeRemark: '', // 房型备注
      /* 新建设施 */
      name: '', // 设施名称
      code: '', // 设施编码
      remark: '', // 设施备注
      /* 提示文本 */
      dev_24_status: '设备24小时状态图',
      sel_time_range: '请选择时间范围！',
      no_state: '暂无状态！',
      confirm: '确定',
      online_status_no_data: '设备24小时一直处于在线状态，无状态数据！',
      offline_status_no_data: '设备24小时一直处于离线状态，无状态数据！',
      hotel_no_null: '酒店名称不能为空！',
      hotel_code_no_null: '酒店编码不能为空！',
      hotel_type_no_null: '酒店类型不能为空！',
      sel_the_unit: '请选择所属单位！',
      room_type_name_no_null: '房型名称不能为空！',
      room_type_code_no_null: '房型编码不能为空！',
      facilitys_name_no_null: '设施名称不能为空！',
      facilitys_code_no_null: '设施编码不能为空！',
      open_s: '开',
      close_s: '关',
      yes: '是',
      no: '否',
      add_success: '添加成功！',
      update_success: '修改成功！',
      prompt: '提示！',
      confirm_edit: '确定修改？',
      confirm_remove: '确定移除？',
      remove_success: '移除成功！',
      bind_success: '绑定成功！',
    }
  },
  mounted() {
    Object.assign(this, this.$route.query)
    if (this.action === 'edit') {
      this.getHotelDetail()
      this.getSelectApplets()
      this.echoPayCfg()
      this.getMerchantInfo()
    } else {
      this.getRoomTypes()
      this.getFacilities()
      this.getAppletList()
    }
  },
  watch: {
    isCheckYsepay(isYespay) {
      isYespay ? (this.isYspay = true) : (this.isYspay = false)
    },
  },
  methods: {
    // 获取酒店详情
    getHotelDetail() {
      const url = urlObj.getHotelDetail + `/${this.hotelId}`
      this.$axios
        .get(url, {})
        .then((res) => {
          if (res.success) {
            let data = res.records
            this.star = data.star
            this.area = data.area
            this.reserveDayMax = data.reserveDayMax
            if (data.firstCheckTime) {
              this.inTime = [
                this.filterDate(data.firstCheckTime),
                this.filterDate(data.lateCheckTime),
              ]
            }
            if (data.breakfastBeginTime) {
              this.breakfastTime = [
                this.filterDate(data.breakfastBeginTime),
                this.filterDate(data.breakfastEndTime),
              ]
            }
            this.checkoutTime = data.checkoutTime
            if (data.frontDeskTel) {
              this.frontDeskTel.areaCode = data.frontDeskTel.split('-')[0]
              this.frontDeskTel.faxNumber = data.frontDeskTel.split('-')[1]
            }
            this.website = data.website
            this.appletsHotelListType = data.appletsHotelListType
            this.checkinNotes = data.checkinNotes
            this.greeting = data.greeting
            this.reserveNotes = data.reserveNotes
            this.description = data.description
            this.checkRoomTypes = data.roomTypes.map((item) => item.roomTypeId)
            this.checkFacilities = data.facilitys.map((item) => item.id)
            this.getRoomTypes()
            this.getFacilities()
          }
        })
        .catch((error) => {
          this.$message.error(error)
        })
    },
    //平台抽佣校验
    verifyRate(val) {
      if (val != '') {
        let reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
        if (!reg.test(val)) {
          this.hotelCfg.WECHAT_PROVIDER.rate = ''
          this.$message.error('输入有误,只能保留两位小数')
          return
        }
        if (Number(val) < 0 || Number(val) > 30) {
          this.hotelCfg.WECHAT_PROVIDER.rate = ''
          this.$message.error('输入有误,只能输入0~30以内的值')
          return
        }
      }
    },
    // 获取酒店房型列表
    getRoomTypes() {
      const url = urlObj.roomTypeList
      this.$axios.post(url, {}, 'json').then((res) => {
        if (res.success) {
          let roomTypes = res.records
          const length = roomTypes.length
          if (length === 0) return (this.roomTypes = this.menuList(length))
          roomTypes = roomTypes.map((item) => {
            return { id: item.id, roomTypeName: item.roomTypeName }
          })
          this.roomTypes = [...roomTypes, ...this.menuList(length)]
          // 设置全选的状态
          if (this.checkRoomTypes.length === roomTypes.length) {
            this.isRoomTypeAll = true
          } else if (
            this.checkRoomTypes.length !== roomTypes.length &&
            this.checkRoomTypes.length !== 0
          ) {
            this.isRoomTypeIndet = true
          }
        }
      })
    },
    // 全选房型列表
    checkAllRoomType(val) {
      if (val) {
        this.checkRoomTypes = this.roomTypes
          .filter((item) => {
            return item.hasOwnProperty('id')
          })
          .map((item) => item.id)
      } else {
        this.checkRoomTypes = []
      }
      this.isRoomTypeIndet = false
    },
    // 单选房型列表
    checkedRoomType(val) {
      let length = this.roomTypes.filter((item) =>
        item.hasOwnProperty('id')
      ).length
      let checkedCount = val.length
      this.isRoomTypeIndet = checkedCount > 0 && checkedCount < length
      this.isRoomTypeAll = checkedCount === length
    },
    // 获取全部设施列表
    getFacilities() {
      const url = urlObj.getFacilityList
      this.$axios.post(url, {}, 'json').then((res) => {
        if (res.success) {
          let facilities = res.records
          let length = facilities.length
          if (length === 0) return (this.facilities = this.menuList(length))
          facilities = facilities.map((item) => {
            return { id: item.id, name: item.name }
          })
          this.facilities = [...facilities, ...this.menuList(length)]
          // 设置全选的状态
          if (this.checkFacilities.length === facilities.length) {
            this.isAppletAll = true
          } else if (
            this.checkFacilities.length !== facilities.length &&
            this.checkFacilities.length !== 0
          ) {
            this.isFacilityIndet = true
          }
        }
      })
    },
    // 全选设施列表
    checkAllFacility(val) {
      if (val) {
        this.checkFacilities = this.facilities
          .filter((item) => {
            return item.hasOwnProperty('id')
          })
          .map((item) => item.id)
      } else {
        this.checkFacilities = []
      }
      this.isFacilityIndet = false
    },
    // 单选酒店设施
    checkedFacility(val) {
      let length = this.facilities.filter((item) =>
        item.hasOwnProperty('id')
      ).length
      let checkedCount = val.length
      this.isFacilityIndet = checkedCount > 0 && checkedCount < length
      this.isFacilityAll = checkedCount === length
    },
    // 判断数字长度返回数组
    menuList(records) {
      let tempList = []
      let tempAdd = {}
      let recordsLength = 6
      if (records % recordsLength !== 0) {
        recordsLength = recordsLength - (records % recordsLength)
      }
      for (let j = 0; j < recordsLength; j++) {
        if (j === 0) {
          tempAdd.addTemp = true
        }
        tempAdd.tempShow = true
        tempList.push(tempAdd)
        tempAdd = {}
      }
      return tempList
    },
    // 回显支付及赛菲姆云配置
    echoPayCfg() {
      const url = urlObj.hotelCfg
      const param = { hotelId: this.hotelId }
      this.$axios.post(url, param, 'json').then((res) => {
        if (res.success) {
          let cfgData = res.records
          if (cfgData.length === 0) return
          cfgData.forEach((item) => {
            this.hotelCfg[item.cfgCode.replace('-', '_').toLocaleUpperCase()] =
              JSON.parse(item.content || '{}')
          })
        }
      })
    },
    // 获取已选小程序菜单
    getSelectApplets() {
      const url = urlObj.getAppletMenu + `/${this.hotelId}`
      this.$axios.get(url, {}).then((res) => {
        if (res.success) {
          let checkMenus = res.children
          // 这里存储的数据会根据用户的操作而改变
          this.checkMenus = checkMenus.map((item) => item.id)
          // 将返回的菜单列表 用深拷贝再存储一份用于与改变后的新数据做对比
          this.storageMenus = JSON.parse(JSON.stringify(this.checkMenus))
          this.getAppletList()
        }
      })
    },
    // 获取小程序的菜单列表
    getAppletList() {
      const url = system.menu
      const param = { client: 'APPLET' }
      this.$axios.post(url, param).then((res) => {
        if (res.success) {
          let appletMenus = res.children
          this.appletMenus = appletMenus.map((item) => {
            return { id: item.id, name: item.name }
          })
          // 设置全选的状态
          if (this.checkMenus.length === this.appletMenus.length) {
            this.isAppletAll = true
          } else if (
            this.checkMenus.length !== this.appletMenus.length &&
            this.checkMenus.length !== 0
          ) {
            this.isAppletIndet = true
          }
        }
      })
    },
    // 全选小程序菜单
    checkAllAppletMenu(val) {
      this.checkMenus = val ? this.appletMenus.map((item) => item.id) : []
      this.isAppletIndet = false
    },
    // 单选小程序菜单
    checkedAppletMenu(val) {
      let checkedCount = val.length
      this.isAppletIndet =
        checkedCount > 0 && checkedCount < this.appletMenus.length
      this.isAppletAll = checkedCount === this.appletMenus.length
    },
    // 改变分账方式
    changeProfiting(val) {
      console.log(val)
      if (!val) return
      const url = urlObj.openProfiting
      const param = { hotelId: this.hotelId }
      this.$axios
        .post(url, param)
        .then((res) => {
          if (res.status == 200) {
            this.$message.success('设置成功')
          }
        })
        .catch(() => (this.hotelCfg.WECHAT_PROVIDER.profitSharing = val))
    },
    // 保存酒店详情
    saveHotelDetail() {
      if (this.ysepayStatus !== '01')
        return this.$message.warning(
          '银盛支付进件资料审核中，不能修改为微信子商户'
        )
      if (this.hotelCfg.WECHAT_PROVIDER.rate == '')
        this.hotelCfg.WECHAT_PROVIDER.rate = '2'
      const url = urlObj.editHotelDetails
      const param = {
        id: this.hotelId,
        star: this.star,
        area: this.area,
        reserveDayMax: this.reserveDayMax,
        firstCheckTime: this.returnDate(this.inTime[0]), // 最早入住时间
        lateCheckTime: this.returnDate(this.inTime[1]), // 最晚入住时间
        breakfastBeginTime: this.returnDate(this.breakfastTime[0]), // 早餐开始时间
        breakfastEndTime: this.returnDate(this.breakfastTime[1]), // 早餐结束时间
        checkoutTime: this.checkoutTime,
        frontDeskTel: this.frontDeskTel.faxNumber,
        website: this.website,
        appletsHotelListType: this.appletsHotelListType,
        checkinNotes: this.checkinNotes,
        greeting: this.greeting,
        reserveNotes: this.reserveNotes,
        description: this.description,
        facilityIds: this.checkFacilities.toString(),
        roomTypeIds: this.checkRoomTypes.toString(),
        hotelCfg: new CustomObject(this.hotelCfg).filterKey(),
      }
      this.frontDeskTel.areaCode &&
        (param.frontDeskTel =
          this.frontDeskTel.areaCode + '-' + this.frontDeskTel.faxNumber)
      this.checkoutTime &&
        this.checkoutTime.length <= 5 &&
        (param.checkoutTime = this.checkoutTime + ':00')
      this.$axios.post(url, param, 'json').then((res) => {
        if (res.success) {
          this.$message({
            showClose: true,
            message: this.add_success,
            type: 'success',
          })
          // 保存小程序菜单选项
          this.saveAppletMenu()
          // 设置为银盛支付
          this.setYspay()
        }
      })
    },
    // 查询商户信息
    async getMerchantInfo() {
      try {
        const res = await getMerchantINfo({ hotelId: this.hotelId })
        console.log('查询商户信息:', res)
        if (res) {
          res.isCheckYsepay === 'YES'
            ? (this.isCheckYsepay = true)
            : (this.isCheckYsepay = false)
          this.ysepayCommissionRate = res.ysepayCommissionRate
          this.ysepayStatus = res.incomingState
        }
      } catch (error) {
        console.log('查询商户信息失败:', error)
      }
    },
    // 设置为银盛支付
    async setYspay() {
      try {
        const res = await setYspay({
          companyId: sessionStorage.getItem('companyId'),
          hotelId: this.hotelId,
          isCheckYsepay: this.isCheckYsepay ? 'YES' : 'NO',
          ysepayCommissionRate: this.isCheckYsepay
            ? this.ysepayCommissionRate
            : 0,
        })
        console.log('设置为银盛支付:', res)
      } catch (error) {
        console.log(error)
      }
    },
    // 找出小程序菜单新增/删除值
    compareArray() {
      // 循环存储的旧菜单列表
      this.storageMenus.forEach((item) => {
        // 如果在新菜单列表中没有找到该ID 则是需要删除的菜单
        if (!this.checkMenus.includes(item)) {
          this.delMenuIds.push(item)
        }
      })
      // 循环操作后的新菜单列表
      this.checkMenus.forEach((item) => {
        // 如果在旧菜单列表中没有找到该ID 则是需要新增的菜单
        if (!this.storageMenus.includes(item)) {
          this.menuIds.push(item)
        }
      })
    },
    // 保存小程序菜单
    saveAppletMenu() {
      this.compareArray()
      const url = urlObj.authAppletMenu
      const param = {
        delMenuIds: this.delMenuIds,
        menuIds: this.menuIds,
        hotelId: this.hotelId,
      }
      this.$axios.post(url, param, 'json').then((res) => {
        if (res.success) {
          this.$message({
            showClose: true,
            message: '小程序菜单授权成功！',
            type: 'success',
          })
          this.$router.push('/hotel_manage')
        }
      })
    },
    // 重置新建房型
    resetRoomType() {
      this.roomTypeName = ''
      this.roomTypeCode = ''
      this.roomTypeRemark = ''
    },
    // 保存新建房型
    saveRoomType() {
      if (!this.roomTypeName) {
        this.$alert(this.room_type_name_no_null, '', {
          confirmButtonText: this.confirm,
          type: 'error',
        })
        return
      }
      if (!this.roomTypeCode) {
        this.$alert(this.room_type_code_no_null, '', {
          confirmButtonText: this.confirm,
          type: 'error',
        })
        return
      }
      const url = pms.addRoomType
      const param = {
        roomTypeName: this.roomTypeName,
        roomTypeCode: this.roomTypeCode,
        remark: this.roomTypeRemark,
      }
      this.$axios
        .post(url, param)
        .then((res) => {
          if (res.success) {
            this.$message({
              showClose: true,
              message: this.add_success,
              type: 'success',
            })
            this.dialogRoomType = false
            this.getRoomTypes()
          }
        })
        .catch((error) => {
          this.$message.error(error)
        })
    },
    // 重置新建设施
    resetFacility() {
      this.fadioIcon = '1'
      this.name = ''
      this.code = ''
      this.remark = ''
    },
    // 保存新建设施
    saveFacility() {
      if (!this.name) {
        this.$alert(this.facilitys_name_no_null, '', {
          confirmButtonText: this.confirm,
          type: 'error',
        })
        return
      }
      if (!this.code) {
        this.$alert(this.facilitys_code_no_null, '', {
          confirmButtonText: this.confirm,
          type: 'error',
        })
        return
      }
      const url = urlObj.addFacility
      const param = {
        name: this.name,
        code: this.code,
        remark: this.remark,
      }
      return this.$axios
        .post(url, param)
        .then((res) => {
          if (res.success) {
            this.$message({
              showClose: true,
              message: this.add_success,
              type: 'success',
            })
            this.dialogFacility = false
            this.getFacilities()
          }
        })
        .catch((error) => {
          this.$message.error(error)
        })
    },
    // 关闭弹窗
    handleClose(done) {
      this.resetRoomType()
      this.resetFacility()
      done()
    },
    // 校验返回后端自定义时间
    returnDate(val) {
      function time(item) {
        if (item < 10) {
          item = '0' + item
        }
        return item
      }
      let hours = time(val.getHours())
      let minutes = time(val.getMinutes())
      let seconds = time(val.getSeconds())
      let configTime = hours + ':' + minutes + ':' + seconds
      return configTime
    },
    // 校验自定义时间
    filterDate(val) {
      if (!val || val === 'null') {
        return new Date(null, null, null, 0, 0, 0)
      }
      let valLlist = val.split(':')
      let data = new Date(
        null,
        null,
        null,
        valLlist[0],
        valLlist[1],
        valLlist[2]
      )
      return data
    },
    // 设置通知模块
    setNoticeTemplate(source, notifyType) {
      const url = urlObj.eventNotify
      const param = { source, notifyType, hotelId: this.hotelId }
      this.$axios.post(url, param).then((res) => {
        if (res.success) this.$message({ message: '设置成功', type: 'success' })
      })
    },
    // 返回酒店列表页
    goBack() {
      this.$router.push('/hotel_manage')
    },
  },
}
</script>
<style lang="scss">
.cont {
  min-width: 1120px;
}
.hotel-add {
  .det-box {
    /* border: 1px solid red; */
    background: white;
    padding: 10px 20px;
    .box-next {
      width: 60%;
      margin: 0 auto 20px;
      /deep/ .el-input__inner {
        height: 40px;
        line-height: 40px;
      }
    }
    .next-button {
      text-align: center;
    }
    .box-info {
      width: 80%;
      margin: 0 auto;
      /deep/ .el-input__inner {
        height: 40px;
        line-height: 40px;
      }
      .rate-number {
        /deep/ .el-rate__icon {
          height: 40px;
          line-height: 40px;
          font-size: 30px;
        }
      }
    }
    .notice {
      padding: 30px 20px;
    }
    .box_list {
      .el-col-4 {
        height: 50px;
        line-height: 50px;
        padding-left: 15px;
        border: 1px solid rgba(204, 204, 204, 1);
        border-width: 0 1px 1px 0;
        &:nth-child(6n) {
          border-right: 0;
        }
        &:nth-last-child(1),
        &:nth-last-child(2),
        &:nth-last-child(3),
        &:nth-last-child(4),
        &:nth-last-child(5),
        &:nth-last-child(6) {
          border-bottom: 0;
        }
      }
      .box-inline {
        font-size: 16px;
        display: inline-block;
        cursor: pointer;
      }
    }
    .box-bg {
      background: rgba(204, 204, 204, 0.3);
      border-bottom: 1px solid rgba(204, 204, 204, 1);
    }
    .box-border {
      border: 1px solid rgba(204, 204, 204, 1);
    }
  }
  .det-box::after {
    content: '';
    display: block;
    clear: both;
  }
  .upload-demo {
    .el-upload {
      width: 100%;
      .el-upload-dragger {
        width: 100%;
        height: 40px;
        border: solid 1px #dcdfe6;
        text-align: right;
        .el-icon-folder-add {
          color: #c0c4cc;
          margin: 0 10px 0 0;
          font-size: 26px;
          line-height: 40px;
        }
      }
    }
  }
}
</style>
