import request from "@/api/service";

const api = {
    setysPay: "/hotel/merchantincoming",
    getysPay: "",
    page: '/hotel/merchantincoming/page',
    banklist: '/hotel/merchantincoming/pageBanks',
    getByHotelId: `/hotel/merchantincoming/getByHotelId`,  // 查询是否设置银盛支付
    getByid: '/hotel/merchantincoming',
    postIcomeform: '/hotel/merchantincoming/saveMerchantIncoming',  // t提交进件
    // 商户钱包-分页查询
    walletPage: '/hotel/merchantwallet/page',
    // 商户钱包-修改账户状态
    updateState: '/hotel/merchantwallet/updateAccountState',
    // 提现管理-商户账户
    account: '/hotel/withdrawalsRecord/getWalletByHotelId/',
    // 提现管理-提现记录
    recordPage: '/hotel/withdrawalsRecord/getPage',
    // 提现管理-提现
    withdrawals: '/hotel/withdrawalsRecord',
    saveMerchantReport: '/hotel/merchantreport/saveMerchantReport',
    getQrcode: '/hotel/merchantincoming/getQrcode',
    downLoadQrcode: '/hotel/merchantincoming/downloadQrcode',
    merchantReportBymerchantIncomingId: '/hotel/merchantreport/getMerchantReportById',
    merchantReportByhotelId: '/hotel/merchantreport/getByHotelId',
    wxAuth: '/hotel/merchantincoming/wxAuth'  // 实名认证
}

/*
*通过id 实名认证
*/
export function wxAuthById(params) {
    return request({
        url: api.wxAuth + `/${params.id}`,
        method: 'post',
    })
}
/*
*通过hotelId查询商户报备信息查询
*/
export function merchantReportByhotelId(params) {
    return request({
        url: api.merchantReportByhotelId + `/${params.hotelId}`,
    })
}

/*
*通过merchantIncomingId查询
*/
export function merchantReport(params) {
    return request({
        url: api.merchantReportBymerchantIncomingId + `/${params.merchantIncomingId}`,
    })
}
/*
* 下载二维码
*/
export function downLoadQrcode(params) {
    return request({
        url: api.downLoadQrcode + `/${params.id}`,
        responseType: 'blob'
    })
}
/*
* 获取二维码
*/
export function getQrcode(params) {
    return request({
        url: api.getQrcode + `/${params.id}`,
    })
}
/*
*保存报备信息/hotel/merchantreport/saveMerchantReport
*/
export function saveMerchantReport(params) {
    return request({
        url: api.saveMerchantReport,
        method: 'post',
        data: params
    })
}
/*
*商户进件提交
*/
export function postIcomeform(params) {
    return request({
        url: api.postIcomeform,
        method: 'post',
        data: params
    })
}
/*
*pc进件编辑查询
*/
export function getIcomingInfo(params) {
    return request({
        url: api.getByid + `/${params.id}`
    })
}
/*
*搜索银行
*/
export function getBankLists(params) {
    return request({
        url: api.banklist,
        params
    })
}
/*
*查询商户信息
*/
export function getMerchantINfo(params) {
    return request({
        url: api.getByHotelId + `/${params.hotelId}`,
    })
}
/*
*设置是否银盛支付
*/
export function setYspay(data) {
    return request({
        url: api.setysPay,
        method: 'POST',
        data
    })
}
/*
*表格数据
*/
export function getPage(params) {
    return request({
        url: api.page,
        params
    })
}

// 商户钱包-分页查询
export function getWalletPage(params) {
    return request({
        url: api.walletPage,
        params
    })
}

// 商户钱包-修改账户状态
export function updateAccountState(data) {
    return request({
        url: api.updateState,
        method: 'POST',
        data
    })
}

// 提现管理-商户账户
export function getAccount(hotelId) {
    return request({
        url: api.account + hotelId,
    })
}

// 提现管理-提现记录
export function getRecordPage(params) {
    return request({
        url: api.recordPage,
        params
    })
}

// 提现管理-提现
export function withdrawals(data) {
    return request({
        url: api.withdrawals,
        method: 'POST',
        data
    })
}
